import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { UserAttrs } from '../../helpers/tokenHelpers';
import { LoanStatus } from '../../services/loanChangeStatusPut';
import { navigationRequires } from '../../store/actions/navigation';
import { asyncDispatch } from '../../store/asyncDispatch';
import { loggedInSelector } from '../../store/selectors';
import isReadySelector from '../../store/selectors/isReadySelector';
import { PathKeyName, PathName } from '../../views/Workflow';
import Mount from '../Mount';
import { ModalC, useModalC } from '../UI/Modal';

export const RejectMessageNinetyDays = {
  main: (
    <p>
      Tu solicitud de crédito no fue aprobada esta vez, pero no te preocupes, 
      puedes intentarlo de nuevo en 90 días.
      Te avisaremos en el futuro si tenemos un producto que se ajuste a tu perfil.
      
      Gracias por confiar en Juancho Te Presta.
    </p>
  ),
  accept: true,
};

export interface Props extends RouteProps {
  component: React.FC<any>;
  name?: PathKeyName;
  path: PathName | PathName[];
  require?: {
    loanStatus?: LoanStatus[] | LoanStatus;
    attrs?: Partial<UserAttrs>;
  };
}

function ErrorComponent() {
  return <Redirect to="/logout" />;
}

const PrivateRoute: React.FC<Props> = ({
  component: Component,
  name = 'unknown',
  require,
  ...route
}) => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(loggedInSelector);
  const isReady = useSelector(isReadySelector);
  const [modal, openModal] = useModalC();

  return (
    <Route
      {...route}
      render={(props) => {
        return (
          <>
            <ModalC props={modal} />
            <Mount
              loading={!isReady}
              before={async () => {
                if (!loggedIn) {
                  throw new Error(
                    'Trying to enter to a private route without Authorization'
                  );
                }
                if (require) {
                  try {
                    await asyncDispatch(dispatch, navigationRequires)(require);
                  } catch (e) {
                    if (e.message === 'Loan Rejected') {
                      await openModal(RejectMessageNinetyDays);
                      throw e;
                    } else {
                      throw e;
                    }
                  }
                }
              }}
              props={props}
              component={Component}
              errorComponent={ErrorComponent}
            />
          </>
        );
      }}
    />
  );
};

export default PrivateRoute;
